
import { defineComponent } from "vue";
import Container from "../../components/Structure/Container.vue";
import { Header, Textarea, QuestionWrapper } from "../../components/Questions";
import { useRouter } from "vue-router";

export default defineComponent({
  components: {
    Container,
    Header,
    Textarea,
    QuestionWrapper,
  },
  setup() {
    const questionNumber = 7;
    const router = useRouter();
    const title =
      "Do you have any questions or concerns that you’d like to share with the doctor?";

    const onAnswered = () => {
      router.push("recommendation");
    };

    return {
      onAnswered,
      title,
      questionNumber,
    };
  },
});
